





































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import notificationStoreModule from "@/store/modules/notification";
import apptStoreModule from "@/store/modules/appointment";

const { mapGetters: notificationGetters, mapActions: notificationActions } =
  createNamespacedHelpers("NOTIFICATIONS");

const { mapActions: apptActions } = createNamespacedHelpers("APPTS");

import AppBarItem from "./AppBarItem.vue";
import { Notification, Role } from "@/types";

export default Vue.extend<any, any, any, any>({
  components: {
    AppBarItem,
  },
  name: "DefaultNotifications",
  data: () => ({
    appointmentModal: false,
    noti: {} as Notification,
  }),
  computed: {
    ...notificationGetters(["notificationPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...notificationActions([
      "fetchNotificationList",
      "bulkDeleteNotifications",
      "updateNotification",
    ]),
    ...apptActions(["updateAppointment"]),
    deleteNotifications() {
      this.bulkDeleteNotifications(
        this.notificationPage.docs.map((n: Notification) => n._id)
      );
    },
    showNoti(n: Notification) {
      console.log(n.type);
      this.noti = n;
      if (n.type === "appointment") {
        this.appointmentModal = true;
      }
    },
    fetchData() {
      this.fetchNotificationList(
        `?businessId=${this.role.business._id}&status=pending`
      );
    },
    cancel() {
      this.updateAppointment({
        id: this.noti.reference,
        appointment: { status: "cancelled" },
      }).then((apt) => {
        if (apt) {
          this.updateNotification({
            status: "read",
            notificationId: this.noti._id,
          }).then(() => {
            this.fetchData();
          });
          this.appointmentModal = false;
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("NOTIFICATIONS")) {
      this.$store.registerModule("NOTIFICATIONS", notificationStoreModule);
    }
    if (!this.$store.hasModule("APPTS")) {
      this.$store.registerModule("APPTS", apptStoreModule);
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule("NOTIFICATIONS");
    this.$store.unregisterModule("APPTS");
  },
});
